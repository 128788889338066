import { string, array, object } from 'yup'
import { contactPhoneSchema } from 'Shared/containers/ContactPhone/ContactPhone.rules.js'

const colorValidation = string()
  .required('A cor é obrigatória')
  .matches(
    /^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/,
    'A cor deve ser válida em formato hex'
  )

export const BranchSchema = {
  color1: colorValidation,
  color2: colorValidation,
  title: string().required('O titulo é obrigatório'),
  url: string().required('O url é obrigatório'),
  facebook: string(),
  instagram: string(),
  linkedin: string(),
  youtube: string(),
  whatsapp: string(),
  phones: array().of(object().shape(contactPhoneSchema)),
  email: string(),
  homepage: string()
}

export const AnalyticsSchema = {
  googleAnalytics: string(),
  googleAds: string(),
  googleTagManager: string(),
  hotjarId: string(),
  hotjarVersion: string(),
  facebookPixel: string(),
  pinterestTagId: string()
}
