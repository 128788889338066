/** @jsxRuntime classic */
/** @jsx jsx */
import { identity } from '@bonitour/common-functions'
import { css, jsx } from '@emotion/react'
import {
  SidebarSection,
  LogoMenuItem,
  CompanyIcon,
  UsersIcon,
  LogoutIcon,
  MenuItem,
  RoleIndicatorIcon,
  CompanyCard,
  ClickIcon,
  MountainIcon,
  MailIcon
} from '@bonitour/components'
import { NavLink, useLocation } from 'react-router-dom'
import BinamikLogo from 'Shared/svgs/logoBinamik.svg'
import XpertLogo from 'Shared/svgs/logoXpert.svg'
import { useCallback } from 'react'
import { useAuthorization } from 'Shared/contexts/Authorization'
import { useCompany } from 'Shared/contexts/Company'
import { OptimizelyFeatureEnabler } from 'Shared/contexts/FeatureFlags'

const { REACT_APP_ACCOUNTS_URL } = process.env

const marginTop30 = css`
  margin-top: 30px;
`

const marginBottom10 = css`
  margin-bottom: 10px;
`

const clickable = css`
  cursor: pointer;
`

const redirectOnClick = link => () => {
  window.location = link
}

const noDecorationLine = css`
  text-decoration: none;
`

export const Menu = ({ onNavigation = identity }) => {
  const { logout } = useAuthorization()
  const { pathname } = useLocation()
  const isLinkActive = useCallback(
    to => pathname.indexOf(to) !== -1,
    [pathname]
  )
  const { company } = useCompany()
  const { REACT_APP_HOST_ORB_URI: ORB_URI } = process.env

  return (
    <div>
      <SidebarSection>
        <NavLink css={[noDecorationLine]} to='/'>
          <LogoMenuItem
            onClick={redirectOnClick('/')}
            logoSrc={XpertLogo}
            css={marginBottom10}
          />
        </NavLink>

        <CompanyCard
          name={company.name}
          image={company.image && `${ORB_URI}/${company.image}`}
        />

        <NavLink css={noDecorationLine} to='/app/appearance'>
          <MenuItem
            onClick={onNavigation}
            icon={ClickIcon}
            label='Meu Site'
            isActive={isLinkActive('/app/appearance')}
          />
        </NavLink>

        <NavLink css={noDecorationLine} to='/app/algorithm-setup'>
          <MenuItem
            onClick={onNavigation}
            icon={RoleIndicatorIcon}
            label='Roteirização'
            isActive={isLinkActive('/app/algorithm-setup')}
          />
        </NavLink>

        <OptimizelyFeatureEnabler feature='xpert-backoffice-custom-regions'>
          {enabled => (
            <NavLink
              css={noDecorationLine}
              to={enabled ? '/app/regions' : '/app/experiences'}
            >
              <MenuItem
                onClick={onNavigation}
                icon={MountainIcon}
                label='Experiências'
                isActive={isLinkActive('/app/experiences')}
              />
            </NavLink>
          )}
        </OptimizelyFeatureEnabler>

        <NavLink css={noDecorationLine} to='/app/custom-text'>
          <MenuItem
            onClick={onNavigation}
            icon={MailIcon}
            label='E-mails'
            isActive={isLinkActive('/app/experiences')}
          />
        </NavLink>

        <LogoMenuItem
          onClick={redirectOnClick(`${REACT_APP_ACCOUNTS_URL}/app`)}
          logoSrc={BinamikLogo}
          css={[marginTop30, clickable]}
        />

        <MenuItem
          onClick={redirectOnClick(
            `${REACT_APP_ACCOUNTS_URL}/app/company/edit`
          )}
          icon={CompanyIcon}
          label='Empresa'
          isActive={isLinkActive('/company/edit')}
        />

        <MenuItem
          onClick={redirectOnClick(`${REACT_APP_ACCOUNTS_URL}/app/users`)}
          icon={UsersIcon}
          label='Usuários'
          isActive={isLinkActive('/users')}
        />

        <MenuItem onClick={logout} icon={LogoutIcon} label='Sair' />
      </SidebarSection>
    </div>
  )
}
