/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useMemo, useCallback } from 'react'
import { jsx, css } from '@emotion/react'
import { useForm } from '@bonitour/app-functions'

import {
  Button,
  Row,
  MaskedInput,
  useToast,
  InputFormGroup,
  colors,
  GhostButton
} from '@bonitour/components'

import {
  formStyle,
  saveButton,
  rowButtons,
  helpInfo,
  formLabel
} from '../Appearance.style'

import { patchSiteConfig, postSiteConfig } from '../Appearance.io'

import { unlinkIcon } from 'Domains/Dashboard/Documents/NoConfig'

import { AnalyticsSchema } from '../forms/schemas'
import {
  formatGoogleAds,
  formatGoogleAnalytics,
  formatGoogleTagManager
} from '../forms/formatters'
import { ContentCard } from './ContentCard'
import { ExternalLink } from './ExternalLink'
import { useHistory } from 'react-router'
import { usePermission } from 'Shared/contexts/Permissions'

const alertContainerCss = css`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  text-align: center;

  padding: 64px;

  svg {
    opacity: 7%;
    width: 140px;
    height: 140px;
  }

  p {
    margin-top: 32px;
    color: ${colors.gray3};
    line-height: 20px;
  }
`

export const NoUrlAlert = ({ tabLabel = 'integrações' }) => {
  return (
    <div css={alertContainerCss}>
      {unlinkIcon}
      <p>
        Antes você precisa definir a url do seu E-commerce Xpert
        <br />
        Para configurar suas {tabLabel}
      </p>
    </div>
  )
}

export function AnalyticsConfigForm({ config, setConfig, companyId }) {
  const { add: addToast } = useToast()

  const initialFormData = useMemo(
    () => ({
      googleAnalytics: config.google_analytics || '',
      googleAds: config.google_ads || '',
      hotjarId: config.hotjar?.id || '',
      hotjarVersion: config.hotjar?.version || '',
      facebookPixel: config?.facebook_pixel || '',
      googleTagManager: config?.google_tag_manager || '',
      pinterestTagId: config?.pinterest_tag_id || ''
    }),
    [config]
  )

  const {
    form,
    onSubmit,
    utils: { onInputBlur, onInputChange }
  } = useForm(initialFormData, AnalyticsSchema)

  const onFormError = useCallback(
    err => !err?.phones && window.scrollTo({ top: 550, behavior: 'smooth' }),
    []
  )

  const { allowed: canSave } = usePermission({
    permission: 'xpert_backoffice',
    action: 'update'
  })

  const save = () => {
    if (!canSave) {
      return addToast(
        'Usuário sem permissão de escrita/atualização do Backoffice'
      )
    }
    const isPatch = Boolean(config?.url)
    const hotjarExists = form.hotjarId && form.hotjarVersion
    const payload = {
      hotjar: hotjarExists
        ? JSON.stringify({
            id: form.hotjarId,
            version: form.hotjarVersion
          })
        : undefined,
      google_ads: form.googleAds || '',
      google_analytics: form.googleAnalytics || '',
      facebook_pixel: form.facebookPixel || '',
      google_tag_manager: form.googleTagManager || '',
      pinterest_tag_id: form.pinterestTagId || ''
    }

    ;(isPatch ? patchSiteConfig : postSiteConfig)(payload, companyId)
      .then(config => {
        addToast('Configurações salvas com sucesso', 'success')
        setConfig(config)
      })
      .catch(e => {
        console.error(e)
        addToast('Erro ao salvar configurações')
      })
  }

  const { push } = useHistory()
  const goBack = useCallback(() => push('/'), [push])

  return (
    <>
      <ContentCard marginBottom='0' title='Integrações'>
        <p css={helpInfo}>
          Inclua aqui as ferramentas que utiliza para acompanhar e melhorar o
          tráfego dos seus conteúdos.
        </p>
        <ExternalLink
          href='https://ajuda.binamik.com.br/binamik-xpert/configuracoes-do-meu-site-integracoes-de-marketing/'
          title='Saiba mais sobre Integrações em nossa '
          linkText='Central de Ajuda'
        />
        {config?.url ? (
          <Row>
            <div css={[formStyle, formLabel]}>
              <Row>
                <InputFormGroup label='ID Google Analytics'>
                  <p css={helpInfo}>
                    Informe o código do Analytics abaixo para incluir ferramenta
                    de métricas e monitoramento de site
                  </p>
                  <ExternalLink
                    href='https://marketingplatform.google.com/about/analytics/'
                    linkText='suporte do Google'
                  />
                  <MaskedInput
                    id='googleAnalytics'
                    formatterFunction={formatGoogleAnalytics}
                    onChange={onInputChange('googleAnalytics')}
                    onBlur={onInputBlur('googleAnalytics')}
                    maxLength={18}
                    placeholder='UA-88975107-1'
                    value={form.googleAnalytics}
                  />
                </InputFormGroup>
              </Row>
              <Row>
                <InputFormGroup label='ID Google Ads'>
                  <p css={helpInfo}>
                    Informe abaixo seu ID para incluir a ferramenta de anúncios
                    da Google
                  </p>
                  <ExternalLink
                    href='https://ads.google.com/intl/pt-BR_br/home/'
                    linkText='Google Ads'
                  />
                  <MaskedInput
                    id='googleAds'
                    formatterFunction={formatGoogleAds}
                    maxLength={18}
                    onChange={onInputChange('googleAds')}
                    onBlur={onInputBlur('googleAds')}
                    placeholder='AW-897846394'
                    value={form.googleAds}
                  />
                </InputFormGroup>
              </Row>
              <Row>
                <InputFormGroup label='ID do GTM'>
                  <p css={helpInfo}>
                    Informe o ID do gerenciador de Tags abaixo
                  </p>
                  <ExternalLink
                    href='https://support.google.com/tagmanager/answer/6102821?hl=pt-BR&ref_topic=3441530'
                    linkText='suporte do Google'
                  />
                  <MaskedInput
                    id='googleTagManager'
                    formatterFunction={formatGoogleTagManager}
                    onChange={onInputChange('googleTagManager')}
                    onBlur={onInputBlur('googleTagManager')}
                    maxLength={16}
                    placeholder='GTM-PMKDNSB'
                    value={form.googleTagManager}
                  />
                </InputFormGroup>
              </Row>
              <Row>
                <InputFormGroup label='ID Facebook Pixel'>
                  <p css={helpInfo}>
                    Informe o código ou ID do Facebook pixel abaixo
                  </p>
                  <ExternalLink
                    href='https://pt-br.facebook.com/business/learn/facebook-ads-pixel'
                    linkText='Facebook para business'
                  />
                  <MaskedInput
                    id='facebookPixel'
                    value={form.facebookPixel}
                    onChange={onInputChange('facebookPixel')}
                    onBlur={onInputBlur('facebookPixel')}
                    maxLength={20}
                    placeholder='15391649785138078'
                    formatterFunction={v => v?.replace(/\W|[A-Za-z]/g, '')}
                  />
                </InputFormGroup>
              </Row>
              <Row>
                <InputFormGroup label='ID do Pinterest'>
                  <p css={helpInfo}>
                    Informe o código ou ID de integração com o Pinterest
                  </p>
                  <ExternalLink
                    href='https://help.pinterest.com/pt-br/business/article/install-the-base-code'
                    linkText='Integração com pinterest'
                  />
                  <MaskedInput
                    id='pinterestTagId'
                    formatterFunction={v => v.replace(/\D/, '')}
                    onChange={onInputChange('pinterestTagId')}
                    onBlur={onInputBlur('pinterestTagId')}
                    maxLength={13}
                    placeholder='9346185497562'
                    value={form.pinterestTagId}
                  />
                </InputFormGroup>
              </Row>
            </div>
          </Row>
        ) : (
          <NoUrlAlert />
        )}
      </ContentCard>
      <Row customCss={[rowButtons]}>
        <GhostButton css={saveButton} onClick={goBack}>
          Voltar
        </GhostButton>
        {config?.url && (
          <Button
            customCss={[saveButton]}
            onClick={onSubmit(save, onFormError)}
          >
            Salvar
          </Button>
        )}
      </Row>
    </>
  )
}
