import { getFeatureFlags } from 'external/axios'
import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect
} from 'react'
import {
  createInstance,
  OptimizelyProvider,
  OptimizelyFeature
} from '@optimizely/react-sdk'
import xhook from 'xhook'
import { useUser } from './User'

const { REACT_APP_OPTIMIZELY_KEY, REACT_APP_OPTIMIZELY_CDN_PROXY } = process.env

xhook.before(request => {
  if (REACT_APP_OPTIMIZELY_CDN_PROXY) {
    request.url = request.url.replace(
      'https://cdn.optimizely.com/',
      REACT_APP_OPTIMIZELY_CDN_PROXY
    )
  }
})

const optimizely = createInstance({ sdkKey: REACT_APP_OPTIMIZELY_KEY })

export const FeatureFlagsContext = createContext({})

/** @type function(): Record<string, boolean> */
export const useFeatureFlags = () => useContext(FeatureFlagsContext)

export const FeatureFlagsProvider = props => {
  const [featureFlagsResponse, setFeatureFlags] = useState({})

  const updateFeatureFlags = useCallback((run = 0) => {
    const MAX_REPEATED_RUNS = 10
    if (run > MAX_REPEATED_RUNS) return {}
    return getFeatureFlags()
      .then(setFeatureFlags)
      .catch(() => setTimeout(updateFeatureFlags(run + 1), 1000))
  }, [])

  useEffect(() => {
    updateFeatureFlags()
  }, [updateFeatureFlags])

  const envType = useMemo(() => {
    const { REACT_APP_ENVIRONMENT } = process.env
    return (
      {
        staging: 'dev',
        development: 'dev',
        homologation: 'homolog'
      }[REACT_APP_ENVIRONMENT] || 'prod' // TODO 'canary' for specific company ids
    )
  }, [])

  const providerData = useMemo(
    () =>
      Object.keys(featureFlagsResponse).reduce(
        (acc, key) => ({
          ...acc,
          [key]: featureFlagsResponse[key]?.[envType] || false
        }),
        {}
      ),
    [envType, featureFlagsResponse]
  )

  // OptimizelyProps:
  const { user } = useUser()
  const userInfo = useMemo(() => {
    const { id, ...attributes } = user
    return {
      id,
      attributes
    }
  }, [user])

  return (
    <OptimizelyProvider optimizely={optimizely} user={userInfo}>
      <FeatureFlagsContext.Provider value={providerData} {...props} />
    </OptimizelyProvider>
  )
}

export const OptimizelyFeatureEnabler = OptimizelyFeature
