/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { jsx } from '@emotion/react'
import {
  Row,
  useToast,
  ConfirmDialog,
  H3,
  H2,
  HeaderPage
} from '@bonitour/components'
import { LoadingContainer } from '../../../components/LoadingContainer/LoadingContainer'
import { LanguageTabs } from 'components/I18n/LanguageTabs/LanguageTabs'
import { useParams } from 'react-router-dom'
import {
  deleteImage,
  getConfig,
  patchConfig,
  patchImagemAlt,
  postImage,
  savedLangs,
  setConfigField,
  setImageDescription,
  unsavedLangs
} from './ExperienceConfigManager'
import {
  OptimizelyFeatureEnabler,
  useFeatureFlags
} from 'Shared/contexts/FeatureFlags'
import { NewLanguageModal } from 'components/I18n/LanguageTabs/NewLanguageModal'
import {
  buttonOnClose,
  heading,
  modalConfirm
} from './ExperienceConfigContainer.style'
import {
  usePermission,
  useRequirePermission
} from 'Shared/contexts/Permissions'
import { ExperienceConfigurations } from './ExperienceConfiguration'

export const ExperienceConfigContainer = ({ match, history }) => {
  const [currentTab, setCurrentTab] = useState('pt-br')
  /**
   * @type {[
   *   import('./ExperienceConfigManager').FormOutput,
   *   function (import('./ExperienceConfigManager').FormOutput): void
   * ]}
   */
  const [form, setForm] = useState({})
  const [loading, setLoading] = useState(true)
  const [availableTabs, setAvailableTabs] = useState([])
  const [newLanguageModal, setNewLanguageModal] = useState(false)
  const [hasEdit, setHasEdit] = useState(false)
  const [openingTab, setOpeningTab] = useState('')
  const { add: addToast } = useToast()
  const { experienceConfigTranslation, rulesAndObservationFields, images } =
    useFeatureFlags()
  useRequirePermission({
    permission: 'xpert_backoffice'
  })

  const { allowed: canSave } = usePermission({
    permission: 'xpert_backoffice',
    action: 'update'
  })

  useEffect(() => {
    if (!availableTabs.some(tab => tab.value === currentTab)) {
      setCurrentTab('pt-br')
    }
  }, [availableTabs, currentTab])

  const loadConfig = useCallback(() => {
    setLoading(true)
    getConfig(match?.params?.id)
      .then(value => {
        setForm(value)
        setHasEdit(false)
        if (!availableTabs?.length) {
          setAvailableTabs(savedLangs(value))
        }
      })
      .catch(() => {
        addToast('Erro ao carregar as configurações', 'danger')
      })
      .finally(() => setLoading(false))
  }, [addToast, availableTabs, match])

  useEffect(() => {
    loadConfig()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id])

  const onChange = lang => field => (value, id) => {
    setHasEdit(true)
    switch (field) {
      case 'form':
        setForm(value)
        break
      case 'images':
        setForm(setImageDescription(form, id, lang, value))
        break
      default:
        setForm(setConfigField(form, field, lang, value))
        break
    }
  }

  const patchImagesAltData = useCallback(
    images => {
      return patchImagemAlt({ id: match?.params?.id, images })
    },
    [match]
  )

  const updateData = useCallback(
    (param, isReset = false) => {
      if (!canSave) {
        return addToast(
          'Usuário sem permissão de escrita/atualização do Backoffice'
        )
      }
      const paramIsEvent = (param?.nativeEvent || param) instanceof Event
      const dataToSend = paramIsEvent ? form : param

      const newImages = Object.keys(dataToSend?.images).reduce(
        (acc, imageId) => ({
          ...acc,
          [imageId]: { description: dataToSend?.images[imageId].description }
        }),
        {}
      )

      setLoading(true)
      patchConfig(dataToSend)
        .then(() => {
          if (Object.keys(newImages).length) {
            patchImagesAltData(newImages)
              .then(() => {
                addToast(
                  `Configurações ${
                    isReset ? 'resetadas' : 'salvas'
                  } com sucesso`,
                  'success'
                )
              })
              .catch(() => {
                addToast(
                  `Erro ao ${isReset ? 'resetar' : 'salvar'} as configurações`,
                  'danger'
                )
              })
              .finally(() => loadConfig())
          } else {
            addToast(
              `Configurações ${isReset ? 'resetadas' : 'salvas'} com sucesso`,
              'success'
            )
            loadConfig()
          }
        })
        .catch(() => {
          addToast(
            `Erro ao ${isReset ? 'resetar' : 'salvar'} as configurações`,
            'danger'
          )
          loadConfig()
        })
    },
    [addToast, form, loadConfig, patchImagesAltData, canSave]
  )

  const uploadImageData = useCallback(
    file => {
      if (!canSave) {
        return addToast(
          'Usuário sem permissão de escrita/atualização do Backoffice'
        )
      }
      setLoading(true)
      postImage({ image: file[0], id: match?.params?.id })
        .then(data => {
          setForm({
            ...form,
            images: {
              [data.id]: {
                image_url: data.image_url,
                description: {},
                id: data.id
              },
              ...form.images
            }
          })
          addToast('Imagem salva com sucesso', 'success')
        })
        .catch(() => {
          addToast('Erro ao salvar a imagem', 'danger')
        })
        .finally(() => setLoading(false))
    },
    [addToast, form, match, canSave]
  )

  const deleteImageData = useCallback(
    file => {
      if (!canSave) {
        return addToast(
          'Usuário sem permissão de escrita/atualização do Backoffice'
        )
      }
      setLoading(true)
      const newImages = JSON.parse(JSON.stringify(form.images))
      delete newImages[file.id]
      deleteImage({ id: match?.params?.id, image_id: file.id })
        .then(() => {
          setForm({
            ...form,
            images: {
              ...newImages
            }
          })
          addToast('Imagem deletada com sucesso', 'success')
        })
        .catch(() => {
          addToast('Erro ao deletar a imagem', 'danger')
        })
        .finally(() => setLoading(false))
    },
    [addToast, form, match, canSave]
  )

  const languageOptions = useMemo(
    () => unsavedLangs(availableTabs),
    [availableTabs]
  )

  const changeTab = useCallback(
    lang => {
      if (lang === currentTab) {
        return
      }

      if (hasEdit) {
        setOpeningTab(lang)
      } else {
        setCurrentTab(lang)
      }
    },
    [currentTab, hasEdit]
  )

  const onConfirm = useCallback(
    lang => {
      if (lang === '') {
        addToast('Idioma não encontrado')
        return
      }

      setAvailableTabs([
        ...availableTabs,
        { label: lang === 'en-us' ? 'Inglês' : 'Espanhol', value: lang }
      ])
      changeTab(lang)
      setNewLanguageModal(false)
    },
    [addToast, availableTabs, changeTab]
  )

  const addLanguage = useCallback(() => {
    if (hasEdit) {
      setOpeningTab('new')
    } else {
      setNewLanguageModal(true)
    }
  }, [hasEdit])

  const cancelTabChange = useCallback(() => {
    loadConfig()
    if (openingTab === 'new') {
      setNewLanguageModal(true)
    } else {
      setCurrentTab(openingTab)
    }
    setOpeningTab('')
  }, [loadConfig, openingTab])

  const confirmTabChange = useCallback(() => {
    updateData(form)
    if (openingTab === 'new') {
      setNewLanguageModal(true)
    } else {
      setCurrentTab(openingTab)
    }
    setOpeningTab('')
  }, [form, openingTab, updateData])

  const { regionId, regionName } = useParams()

  const goBackToRegion = useCallback(
    customRegionEnabled => () => {
      if (customRegionEnabled) {
        return history.push(`/app/regions/${regionId}/${regionName}`)
      }
      history.push(`/app/experiences/${regionId}/${regionName}`)
    },
    [history, regionId, regionName]
  )

  return (
    <>
      <Row customCss={[heading]}>
        <OptimizelyFeatureEnabler feature='xpert-backoffice-custom-regions'>
          {enabled => (
            <HeaderPage
              title={`Configurar Experiência`}
              subtitle={
                <>
                  <span>
                    <H2>{form?.title?.['pt-br']}</H2>
                    <H3> - {form?.company?.name}</H3>
                  </span>
                  {decodeURIComponent(regionName)}
                </>
              }
              onBack={goBackToRegion(enabled)}
            />
          )}
        </OptimizelyFeatureEnabler>
      </Row>
      <LoadingContainer loading={loading}>
        <>
          {experienceConfigTranslation && (
            <LanguageTabs
              tabs={availableTabs}
              currentTab={currentTab}
              onNewLanguage={addLanguage}
              onTabSelect={changeTab}
              hideButton={availableTabs.length === 3}
            />
          )}
          <ExperienceConfigurations
            form={form}
            currentTab={currentTab}
            onChange={onChange(currentTab)}
            updateData={updateData}
            uploadImageData={uploadImageData}
            deleteImageData={deleteImageData}
            loading={loading}
            rulesAndObservationFields={rulesAndObservationFields}
            images={images}
          />
          {newLanguageModal && (
            <>
              <NewLanguageModal
                onClose={() => setNewLanguageModal(false)}
                onConfirm={onConfirm}
                languageOptions={languageOptions}
              />
            </>
          )}
          <ConfirmDialog
            title='Deseja salvar sua tradução'
            message='Você possui alterações em andamento. Deseja salvar antes de sair?'
            isVisible={hasEdit && !!openingTab}
            successCallback={confirmTabChange}
            cancelCallback={cancelTabChange}
            onClose={() => setOpeningTab('')}
            buttonLabelCancel='Continuar sem salvar'
            buttonLabelConfirm='Salvar'
            customCss={[modalConfirm]}
            customButtonCss={[buttonOnClose]}
          />
        </>
      </LoadingContainer>
    </>
  )
}
