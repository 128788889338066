import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect
} from 'react'
import * as CompanyStore from 'core/Store/Company'
import { CompanyService } from 'core/Service/Company'

export const CompanyContext = createContext({})

export const useCompany = () => useContext(CompanyContext)

export const CompanyProvider = props => {
  const [company] = useState(() => CompanyStore.getCompany() || {})
  const [userCompanies, setUserCompanies] = useState([])

  useEffect(() => {
    if (company.id) {
      CompanyService.listUserCompanies(true).then(setUserCompanies)
    }
  }, [company])

  const providerData = useMemo(
    () => ({
      // @ts-ignore
      get id() {
        return company?.id
      },
      company,
      userCompanies
    }),
    [company, userCompanies]
  )

  return <CompanyContext.Provider value={providerData} {...props} />
}
