// @ts-check
/** @jsxRuntime classic */
/** @jsx jsx */
import { useForm } from '@bonitour/app-functions'
import {
  Button,
  Card,
  HeaderPage,
  LoadingAnimation,
  useToast
} from '@bonitour/components'
import { jsx } from '@emotion/react'
import { useCallback, useEffect, useState, useMemo } from 'react'
import {
  getAlgorithmSetupData,
  patchAlgorithmSetupData
} from './AlgorithmSetup.io'
import { setupSchema } from './AlgorithmSetup.schema'
import {
  baseSetupGroup,
  cardStyles,
  infoBlock,
  loadingStyle,
  lockedSection
} from './AlgorithmSetup.style'
import { useHistory } from 'react-router-dom'
import { LunchFormSection } from './Sections/Lunch'
import { SafetyTimeFormSection } from './Sections/SafetyTime'
import { DistanceFormSection } from './Sections/Distance'
import { ActivityTimeFormSection } from './Sections/ActivityTime'
import { ItineraryPeriodFormSection } from './Sections/ItineraryPeriod'
import { IntelligenceFormSection } from './Sections/Intelligence'
import {
  usePermission,
  useRequirePermission
} from 'Shared/contexts/Permissions'
import { OptimizelyFeatureEnabler } from 'Shared/contexts/FeatureFlags'

const initialSetup = { useIntelligence: true }

export function AlgorithmSetup() {
  const {
    form,
    errors,
    onSubmit,
    setForm,
    utils: { onInputBlur, onInputChange }
  } = useForm(initialSetup, setupSchema)
  const { push } = useHistory()
  const { add: addToast } = useToast()
  const [loading, setLoading] = useState(false)
  useRequirePermission({
    permission: 'xpert_backoffice'
  })

  useEffect(() => {
    let canceled = false
    setLoading(true)
    getAlgorithmSetupData()
      .then(parsedForm => {
        if (!canceled) {
          setForm(parsedForm)
          setLoading(false)
        }
      })
      .catch(e => {
        const apiError = e?.response?.data?.error
        if (
          apiError?.errors_msg?.includes('auth::api_key::unauthorized') &&
          apiError?.origin === 'XPERT::DATA'
        ) {
          addToast('É necessário primeiro configurar o "Meu Site"', 'warning')
        } else {
          console.error(e)
          addToast('Erro ao obter configurações')
        }
        push('/')
      })

    return () => {
      canceled = true
      setLoading(false)
    }
  }, [setForm, addToast, push])

  const { allowed: canSave } = usePermission({
    permission: 'xpert_backoffice',
    action: 'update'
  })

  const onFinished = useCallback(async () => {
    if (!canSave) {
      return addToast(
        'Usuário sem permissão de escrita/atualização do Backoffice'
      )
    }
    setLoading(true)
    await patchAlgorithmSetupData(form)
      .then(() => {
        addToast('Configurações salvas com sucesso', 'success')
      })
      .catch(e => {
        console.error(e)
        addToast('Erro ao salvar configurações')
      })
    setLoading(false)
  }, [form, addToast, canSave])

  useEffect(() => {
    const newForm = Object.entries(form).reduce((form, [key, value]) => {
      if (/Enabled$/.test(key) && value === false) {
        const valuePropKey = key.replace(/Enabled$/, 'Value')
        form[valuePropKey] = undefined
      }
      return form
    }, form)

    setForm(newForm)
  }, [form, setForm])

  const useIntelligence = useMemo(() => {
    return form?.useIntelligence || false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form?.useIntelligence])

  const goToDashboard = useCallback(() => {
    push('/app')
  }, [push])

  return (
    <>
      <HeaderPage onBack={goToDashboard} title='Roteirização' />
      <Card padding={30} customCss={[cardStyles]}>
        <blockquote css={infoBlock}>
          Estas informações são necessárias para entregar a melhor opção de
          roteiro para o viajante no Xpert.
        </blockquote>

        <div css={baseSetupGroup}>
          <ItineraryPeriodFormSection
            form={form}
            errors={errors}
            onInputChange={onInputChange}
            onInputBlur={onInputBlur}
          />
        </div>

        <OptimizelyFeatureEnabler feature='intelligence_toggle'>
          {enabled =>
            enabled && (
              <IntelligenceFormSection
                form={form}
                onInputChange={onInputChange}
                onInputBlur={onInputBlur}
              />
            )
          }
        </OptimizelyFeatureEnabler>
        <div css={!useIntelligence ? lockedSection : null}>
          <DistanceFormSection
            form={form}
            errors={errors}
            onInputChange={onInputChange}
            onInputBlur={onInputBlur}
          />
          <SafetyTimeFormSection
            form={form}
            errors={errors}
            onInputChange={onInputChange}
            onInputBlur={onInputBlur}
          />
          <LunchFormSection
            form={form}
            errors={errors}
            onInputChange={onInputChange}
            onInputBlur={onInputBlur}
          />
          <ActivityTimeFormSection
            form={form}
            errors={errors}
            onInputChange={onInputChange}
            onInputBlur={onInputBlur}
          />
        </div>
        <Button onClick={onSubmit(onFinished, console.error)}>Salvar</Button>
        {loading && (
          <div css={loadingStyle}>
            <LoadingAnimation baseColor='#ffffff' />
          </div>
        )}
      </Card>
    </>
  )
}
