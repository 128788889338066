/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { CustomRegionForm } from './CustomRegionForm'
import { OptimizelyFeatureEnabler } from 'Shared/contexts/FeatureFlags'
import { customRegionSchema } from './CustomRegion.schema'
import { useForm } from '@bonitour/app-functions'
import { useCallback } from 'react'
import { createRegion } from '../../io/customRegions.io'
import { HeaderPage, useToast } from '@bonitour/components'
import { useHistory } from 'react-router-dom'

const customRegionBaseForm = {
  agentVisible: true,
  userVisible: true
}

export const NewCustomRegionPage = () => {
  const {
    form,
    errors,
    onSubmit,
    utils: { onInputBlur, onInputChange }
  } = useForm(customRegionBaseForm, customRegionSchema)

  const { add } = useToast()
  const { push } = useHistory()

  const postRegion = useCallback(() => {
    createRegion({
      name: form.name,
      description: form.description,
      agentVisible: form.agentVisible,
      userVisible: form.userVisible
    })
      .then(res => {
        push(`/app/regions/${res.id}/${res.name}`)
        add('Região criada com sucesso', 'success')
      })
      .catch(() => {
        add('Houve um erro ao tentar criar a região', 'error')
      })
  }, [add, form, push])

  const goToList = useCallback(() => {
    push('/app/regions')
  }, [push])

  return (
    <OptimizelyFeatureEnabler feature='xpert-backoffice-custom-regions'>
      {enabled =>
        enabled && (
          <>
            <HeaderPage onBack={goToList} title='Nova Região' />
            <CustomRegionForm
              form={form}
              errors={errors}
              onSubmit={onSubmit(postRegion)}
              onInputChange={onInputChange}
              onInputBlur={onInputBlur}
            />
          </>
        )
      }
    </OptimizelyFeatureEnabler>
  )
}
