import axios from 'axios'
import { AppStore } from '../../Store/App'
import { UserStore } from '../../Store/User'
import { initialize } from '@bonitour/orb-core'
import { identity } from '@bonitour/common-functions'
const { REACT_APP_HOST_ORB_URI } = process.env

export const parseAttribute =
  parser =>
  ({ attributes }, index) =>
    parser(attributes, index)

export const getImageUrl = image => image && `${REACT_APP_HOST_ORB_URI}${image}`

export const OrbService = axios.create({
  baseURL: process.env.REACT_APP_HOST_ORB_URI,
  responseType: 'json'
})

OrbService.interceptors.request.use(
  config => {
    if (UserStore.authKey && !config.headers.Authorization) {
      config.headers.Authorization = UserStore.authKey
    }

    return config
  },
  error => Promise.reject(error)
)

OrbService.interceptors.response.use(identity, error => {
  if (error.response && error.response.status === 401) {
    AppStore.clean()
    window.location.reload()
  }
  return Promise.reject(error)
})

export const extractData = ({ data }) => data

const interceptorFunction = interceptor => {
  interceptor.request.use(
    config => {
      if (UserStore.authKey && !config.headers.Authorization) {
        config.headers.Authorization = UserStore.authKey
      }
      return config
    },
    error => Promise.reject(error)
  )
  interceptor.response.use(identity, error => {
    if (error && error.status === 401) {
      AppStore.clean()
      window.location.reload()
    }
    return Promise.reject(error)
  })
}

export const orbCore = initialize(
  process.env.REACT_APP_HOST_ORB_URI,
  interceptorFunction
)
